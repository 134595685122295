import { render, staticRenderFns } from "./UploadSignedDocumentModal.vue?vue&type=template&id=e56e5c98&"
import script from "./UploadSignedDocumentModal.vue?vue&type=script&lang=ts&"
export * from "./UploadSignedDocumentModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports