























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

enum EVENTS {
  MOUSE_ENTER = "mouseenter",
  MOUSE_LEAVE = "mouseleave",
  MOUSE_DOWN = "mousedown",
  MOUSE_UP = "mouseup",
  FOCUS = "focus",
  BLUR = "blur",
  CLICK = "click",
  INPUT = "input",
  KEY_DOWN = "keydown",
  KEY_UP = "keyup",
  KEY_PRESS = "keypress",
  RESIZE = "resize",
  SCROLL = "scroll",
  TOUCH_START = "touchstart",
  TOUCH_END = "touchend",
  TAB = " Tab"
}

/**
 * Component based on https://github.com/uiv-lib/uiv/blob/dev/src/components/modal/Modal.js (version ^0.36.1).
 * To use the component, create the modal content such as the modal header, body, and footer and pass it as slot content.
 * The ZModal uses v-model (value prop) for tracking whether the modal should show.
 */
@Component({})
export default class ZModal extends Vue {
  $refs!: {
    modal: HTMLDivElement;
    backdrop: HTMLDivElement;
    dialog: HTMLDivElement;  
  };
  @Prop({}) size?: "sm" | "lg";
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: false }) autoFocus!: boolean;
  @Prop({ default: false }) appendToBody!: boolean;
  @Prop({ default: "block" }) displayStyle!: string;
  @Prop({}) beforeClose?: Function;
  @Prop({ default: 20 }) zOffset!: number;
  @Prop({ default: 150 }) transitionDuration!: number;
  @Prop({ default: true }) backdrop!: boolean;
  @Prop({ default: ""}) modalTitle!: string

  private timeoutId: number | undefined;
  private scrollBarWidth: number | null = null;
  private savedScreenSize: {
    width: number;
    height: number;
  } | null = null;
  private isCloseSuppressed: boolean = false;
  private msg: string | undefined = "";

  get sizeClass() {
    return {
      "modal-lg": this.size === "lg",
      "modal-sm": this.size === "sm",
    };
  }

  @Watch("value")
  valueChanged(newVal: boolean) {
    this.$toggle(newVal);
  }

  mounted() {
    this.removeFromDom(this.$refs.backdrop);
    this.on(window, EVENTS.MOUSE_DOWN, this.suppressBackgroundClose);
    this.on(window, EVENTS.KEY_UP, this.onKeyPress);
    if (this.value) {
      this.$toggle(true);
    }
  }

  beforeDestroy() {
    clearTimeout(this.timeoutId);
    this.removeFromDom(this.$refs.backdrop);
    this.removeFromDom(this.$el);
    if (this.getOpenModalNum() === 0) {
      this.toggleBodyOverflow(true);
    }
    this.off(window, EVENTS.MOUSE_DOWN, this.suppressBackgroundClose);
    this.off(window, EVENTS.MOUSE_UP, this.unsuppressBackgroundClose);
    this.off(window, EVENTS.KEY_UP, this.onKeyPress);
  }

  on(element: any, event: EVENTS, handler: any) {
    /* istanbul ignore next */
    element.addEventListener(event, handler);
  }

  off(element: any, event: EVENTS, handler: any) {
    /* istanbul ignore next */
    element.removeEventListener(event, handler);
  }

  getOpenModals() {
    return document.querySelectorAll(".modal-backdrop");
  }

  getOpenModalNum() {
    return this.getOpenModals().length;
  }

  isExist(obj: any) {
    return typeof obj !== "undefined" && obj !== null;
  }

  isPromiseSupported() {
    return typeof window !== "undefined" && this.isExist(window.Promise);
  }

  isElement(el: any) {
    return el && el.nodeType === Node.ELEMENT_NODE;
  }

  addClass(el: Element, className: string) {
    if (!this.isElement(el)) {
      return;
    }
    if (el.className) {
      let classes = el.className.split(" ");
      if (classes.indexOf(className) < 0) {
        classes.push(className);
        el.className = classes.join(" ");
      }
    } else {
      el.className = className;
    }
  }

  removeClass(el: Element, className: string) {
    if (!this.isElement(el)) {
      return;
    }
    if (el.className) {
      let classes = el.className.split(" ");
      let newClasses = [];
      for (let i = 0, l = classes.length; i < l; i++) {
        if (classes[i] !== className) {
          newClasses.push(classes[i]);
        }
      }
      el.className = newClasses.join(" ");
    }
  }

  isIE11() {
    /* istanbul ignore next */
    //@ts-ignore
    return !!window.MSInputMethodContext && !!(document as any).documentMode;
  }

  isIE10() {
    return window.navigator.appVersion.indexOf("MSIE 10") !== -1;
  }

  getComputedStyle(el: any) {
    return window.getComputedStyle(el);
  }

  hasScrollbar(el: any) {
    const hasVScroll = el.scrollHeight > el.clientHeight;
    const style = this.getComputedStyle(el);
    return (
      hasVScroll ||
      style.overflow === EVENTS.SCROLL ||
      style.overflowY === EVENTS.SCROLL
    );
  }

  getScrollbarWidth(recalculate = false) {
    const screenSize = this.getViewportSize();
    // return directly when already calculated & not force recalculate & screen size not changed
    if (
      this.scrollBarWidth !== null &&
      !recalculate &&
      screenSize.height === this.savedScreenSize?.height &&
      screenSize.width === this.savedScreenSize.width
    ) {
      return this.scrollBarWidth;
    }
    /* istanbul ignore next */
    if (document.readyState === "loading") {
      return null;
    }
    const div1 = document.createElement("div");
    const div2 = document.createElement("div");
    div1.style.width = div2.style.width = div1.style.height = div2.style.height =
      "100px";
    div1.style.overflow = "scroll";
    div2.style.overflow = "hidden";
    document.body.appendChild(div1);
    document.body.appendChild(div2);
    this.scrollBarWidth = Math.abs(div1.scrollHeight - div2.scrollHeight);
    document.body.removeChild(div1);
    document.body.removeChild(div2);
    // save new screen size
    this.savedScreenSize = screenSize;
    return this.scrollBarWidth;
  }

  getViewportSize() {
    /* istanbul ignore next */
    const width =
      Math.max(document.documentElement.clientWidth, window.innerWidth) || 0;
    /* istanbul ignore next */
    const height =
      Math.max(document.documentElement.clientHeight, window.innerHeight) || 0;
    return { width, height };
  }

  toggleBodyOverflow(enable: boolean) {
    const MODAL_OPEN = "modal-open";
    const body = document.body;
    if (enable) {
      this.removeClass(body, MODAL_OPEN);
      body.style.paddingRight = "";
    } else {
      const browsersWithFloatingScrollbar = this.isIE10() || this.isIE11();
      const documentHasScrollbar =
        this.hasScrollbar(document.documentElement) ||
        this.hasScrollbar(document.body);
      if (documentHasScrollbar && !browsersWithFloatingScrollbar) {
        body.style.paddingRight = `${this.getScrollbarWidth()}px`;
      }
      this.addClass(body, MODAL_OPEN);
    }
  }

  removeFromDom(el: Element) {
    this.isElement(el) &&
      this.isElement(el.parentNode) &&
      el.parentNode?.removeChild(el);
  }

  onKeyPress(event: any) {
    if (this.value && event.keyCode === 27) {
      const thisModal = this.$refs.backdrop;
      let thisZIndex: number | string = thisModal.style.zIndex;
      thisZIndex =
        thisZIndex && thisZIndex !== "auto" ? parseInt(thisZIndex) : 0;
      // Find out if this modal is the top most one.
      const modals = this.getOpenModals();
      const modalsLength = modals.length;
      for (let i = 0; i < modalsLength; i++) {
        if (modals[i] !== thisModal) {
          let zIndex: number | string = (modals[i] as HTMLElement).style.zIndex;
          zIndex = zIndex && zIndex !== "auto" ? parseInt(zIndex) : 0;
          // if any existing modal has higher zIndex, ignore
          if (zIndex > thisZIndex) {
            return;
          }
        }
      }
      this.toggle(false);
    }
  }

  toggle(show: boolean, msg: string | undefined = undefined) {
    let shouldClose = true;
    if (this.beforeClose && typeof this.beforeClose === "function") {
      shouldClose = this.beforeClose(msg);
    }

    if (this.isPromiseSupported()) {
      // Skip the hiding when beforeClose returning falsely value or returned Promise resolves to falsely value
      // Use Promise.resolve to accept both Boolean values and Promises
      Promise.resolve(shouldClose).then((shouldClose) => {
        // Skip the hiding while show===false
        if (!show && shouldClose) {
          this.msg = msg;
          this.$emit("input", show);
        }
      });
    } else {
      // Fallback to old version if promise is not supported
      // skip the hiding while show===false & beforeClose returning falsely value
      if (!show && !shouldClose) {
        return;
      }

      this.msg = msg;
      this.$emit("input", show);
    }
  }

  $toggle(show: boolean) {
    const modal = this.$el;
    const backdrop = this.$refs.backdrop;
    clearTimeout(this.timeoutId);
    if (show) 
    {
      // If two modals share the same v-if condition the calculated z-index is incorrect,
      // resulting in popover misbehaviour.
      // solved by adding a nextTick.
      // https://github.com/uiv-lib/uiv/issues/342
      this.$nextTick(() => 
      {
        const alreadyOpenModalNum = this.getOpenModalNum();
        document.body.appendChild(backdrop);
        if (this.appendToBody) {
          document.body.appendChild(modal);
        }
        (modal as HTMLElement).style.display = this.displayStyle;
        modal.scrollTop = 0;
        backdrop.offsetHeight; // force repaint
        this.toggleBodyOverflow(false);
        this.addClass(backdrop, "in");
        this.addClass(modal, "in");

      let focusTrapper = function focusTrapper(event: KeyboardEvent, elements: Element[]){            
        if (event.key === 'Tab') {   
          if (event.shiftKey && elements[0] == document.activeElement) {
            event.preventDefault();
            (elements[elements.length-1] as HTMLElement).focus();
          }
          else if (!event.shiftKey && elements[elements.length-1] === document.activeElement) {
            event.preventDefault();
            (elements[0] as HTMLElement).focus();
          }
        }
      }
          
      let focusable = Array.from(this.$refs.modal.querySelectorAll(
          'a,button,input,textarea,select,details,[tabindex]:not([tabindex="-1"])'
        )).filter(el => !el.hasAttribute('disabled'));
      (focusable[0] as HTMLElement).focus();
      this.$refs.modal.addEventListener('keydown', event => {focusTrapper(event,focusable)});
        
      // removed z-index fix as doesn't work, there is now a fix in the source code, so this could be updated to include that
      this.timeoutId = setTimeout(() => {
          if (this.autoFocus) {
            let btn = this.$el.querySelector('[data-action="auto-focus"]');
            if (btn) {
              (btn as HTMLElement).focus();
            }
          }
          this.$emit("show");
          this.timeoutId = 0;
        }, this.transitionDuration);
      });
    } else {
      this.removeClass(backdrop, "in");
      this.removeClass(modal, "in");
      this.timeoutId = setTimeout(() => {
        (modal as HTMLElement).style.display = "none";
        this.removeFromDom(backdrop);
        if (this.appendToBody) {
          this.removeFromDom(modal);
        }
        if (this.getOpenModalNum() === 0) {
          this.toggleBodyOverflow(true);
        }
        this.$emit("hide", this.msg || "dismiss");
        this.msg = "";
        this.timeoutId = 0;
      }, this.transitionDuration);
    }
  }
  
  suppressBackgroundClose(event: Event) {
    if (event && event.target === this.$el) {
      return;
    }

    this.isCloseSuppressed = true;
    this.on(window, EVENTS.MOUSE_UP, this.unsuppressBackgroundClose);
  }
  unsuppressBackgroundClose() {
    if (this.isCloseSuppressed) {
      this.off(window, EVENTS.MOUSE_UP, this.unsuppressBackgroundClose);
      setTimeout(() => {
        this.isCloseSuppressed = false;
      }, 1);
    }
  }
  backdropClicked(event: Event) {
    if (this.backdrop && !this.isCloseSuppressed) {
      this.toggle(false);
    }
  }
}
