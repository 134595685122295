





























































































enum ContractStatus {
  Draft = 395620000,
  SentForSigning = 395620001,
  ExternalSigned = 395620002,
  MbieSigned = 395620003,
}

import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DocumentClient,
  DocumentMetadataClient,
  DocumentMetadataVm,
} from "@/api/clients/documentApi";
import {
  IUploadSignedContractRequestVm,
  SignContractClient, SignedContractRequestVm, UploadSignedContractRequestVm
} from "@/api/clients/signDocumentApi";
import ZModal from "@/shared/components/ZModal.vue";
import Banner from "@/shared/components/Banner.vue";
import SignDocumentModal from "./SignDocument/SignDocumentModal.vue";
import UploadSignedDocumentModal from "./SignDocument/UploadSignedDocumentModal.vue";
import { fileDownload } from "@/shared/HelperFunctions";

@Component({
  name: "file-grid",
  components: {
    ZModal,
    Banner,
    SignDocumentModal,
    UploadSignedDocumentModal
  },
})
export default class FileGrid extends Vue {
  // APIs
  @Prop({ default: "" }) tokenBaseUrl!: string;
  @Prop({ default: "" }) serviceBaseUrl!: string;
  @Prop({ default: "" }) apiKey!: string;

  // Form Setup
  @Prop({ default: false }) orgAdmin!: boolean;
  @Prop({ default: "" }) entityId!: string;

  // Internals
  loading = true;
  signing = false;
  downloading = false;
  docDownloading = "";

  showSignedBanner = false;
  showSignedModal = false;
  showUploadModal = false;
  signDocumentId = '';
  bannerText = '';
  bannerColour = "";

  signClient = new SignContractClient(this.serviceBaseUrl, this.tokenBaseUrl, this.apiKey);
  fileInfos: DocumentMetadataVm[] = [];

  async created() {
    this.loading = true;
    await this.loadRelatedDocuments();
    this.loading = false;
  }

  async loadRelatedDocuments() {
    if (this.entityId !== undefined) {
      const client = new DocumentMetadataClient(
        this.serviceBaseUrl,
        this.tokenBaseUrl,
        this.apiKey
      );
      await client
        .getAll("sims_contract", this.entityId, null, null, null)
        .then((metadata) => {
          this.fileInfos = metadata.filter(m => m.showInDocumentsTab == true);
        })
        .catch((reason) => {
          // handle error
          console.error(reason);
          this.fileInfos = [];
        });
    }
  }

  async downloadFile(fileId: string, fileName: string | undefined, event?: any) {
    if (event) event.preventDefault();
    this.downloading = true;
    this.docDownloading = fileId;

    const documentClient = new DocumentClient(
      this.serviceBaseUrl,
      this.tokenBaseUrl,
      this.apiKey
    );
    documentClient
      .get(fileId)
      .then((x) => {
        fileDownload(x, fileName ?? "");
      })
      .catch((e) => alert(e))
      .finally(() => (this.downloading = false));
  }

  leaveFeedback(fileId: string, event?: any) {
    if (event) event.preventDefault();
    window.location.href = "/enquiries/create/?docId=" + fileId + "&contractId=" + this.entityId;
  }

  signDocumentPopup(fileId: string, event?: any) {
    if (event) event.preventDefault();
    this.signDocumentId = fileId;
    this.showSignedModal = true;
  }

  uploadSignedDocPopup(fileId: string, event?: any) {
    if (event) event.preventDefault();
    this.signDocumentId = fileId;
    this.showUploadModal = true;
  }

  async signDocument() {
    this.signing = true;
    var request = new SignedContractRequestVm({
        contractId: this.entityId,
        documentMedatadaId: this.signDocumentId
    });
    await this.signClient.signContract(request)
    .then(() => {
      this.signDocumentFinished("Thanks for signing this contract. We will be in touch once we have also signed it.", "success");
      this.setAsSigned();
    })
    .catch(() => {
      this.signDocumentFinished("There has been an error signing this contract. Please create an enquiry.", "warning");
    });
  }

  async signUploadedDocument(details: IUploadSignedContractRequestVm) {
    this.signing = true;
    details.contractId = this.entityId;
    details.documentMedatadaId = this.signDocumentId;

    await this.signClient.uploadSignedContract(new UploadSignedContractRequestVm(details))
    .then(() => {
      this.signDocumentFinished("Thanks for signing this contract. We will be in touch once we have also signed it.", "success");
      this.setAsSigned();
    })
    .catch(() => {
      this.signDocumentFinished("There has been an error signing this contract. Please create an enquiry.", "warning");
    });
  }

  signDocumentFinished(text: string, colour: string) {
    this.bannerText = text;
    this.bannerColour = colour;
    this.showSignedModal = false;
    this.showUploadModal = false;
    this.showSignedBanner = true;
    this.signing = false;
  }

  setAsSigned() {
    var file = this.fileInfos.find(f => f.id == this.signDocumentId);
    if (file)
      file.contractStatusCode = ContractStatus.ExternalSigned;
  }
}
