import axios, { AxiosInstance } from 'axios';

export class TokenService {
  protected readonly instance: AxiosInstance;
  
  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
  }

  public getToken = () => this.instance.post<any>(``);
}
