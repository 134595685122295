





















































































































enum DocumentStatus {
  InProgress = 0,
  ScanningError = 2,
  MalwareDetected = 3,
  TooLarge = 4,
  InvalidFormat = 5,
  Successfull = 395620001,
  InteralDocument = 1
}

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import {
  DocumentClient,
  DocumentMetadataClient,
  DocumentMetadataVm,
} from "@/api/clients/documentApi";

import ZModal from "@/shared/components/ZModal.vue";
import { fileDownload } from "@/shared/HelperFunctions";

@Component({
  name: "file-uploader",
  components: {
    ZModal,
  },
})
export default class FileUploader extends Vue {
  // API's
  @Prop({ default: "" }) tokenBaseUrl!: string;
  @Prop({ default: "" }) serviceBaseUrl!: string;
  @Prop({ default: "" }) apiKey!: string;

  // Document Setup
  @Prop({ default: 10 }) maxDocuments!: number;
  @Prop({ default: true }) canDownload!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) multiple!: boolean;
  @Prop({ default: "" }) documentCategoryId!: string;
  @Prop({ default: "" })  documentCategory!: string;

  // Form Setup
  @Prop({ default: "" }) entityName!: string;
  @Prop({ default: "" }) entityId!: string;

  // Event Emmitters
  @Emit("filechange")
  documentChanged() {
    return this.fileInfos.map((x) => x.id).toString();
  }

  @Watch("fileInfos", { deep: true })
  fileInfosChanged() {
    this.documentChanged();
  }

  // Internals
  showDeleteModal = false;
  currentFileName: string = "";
  currentFileId: string = "";
  loading = false;
  downloading = false;
  allowedFileTypes: string[] = ['.txt', '.pdf', '.xla', '.xlc', '.xlm', '.xls', '.xlt', '.xlw', '.docx', '.xlsx'];
  maxFileSize: number = 31703520; // 30mb

  fileInfos: DocumentMetadataVm[] = [];

  async created() {
    await this.loadRelatedDocuments();
  }

  async loadRelatedDocuments() {
    if (this.entityId !== undefined) {
      this.loading = true;
      const client = new DocumentMetadataClient(
        this.serviceBaseUrl,
        this.tokenBaseUrl,
        this.apiKey
      );
      await client
        .getAll(this.entityName, this.entityId, null, null, null)
        .then((metadata) => {
          this.fileInfos = metadata;
        })
        .catch((reason) => {
          // handle error
          console.error(reason);
        });
      this.loading = false;
    }
  }

  async downloadFile(fileId: string, fileName: string | undefined, event?: any) {
    this.downloading = true;
    const documentClient = new DocumentClient(
      this.serviceBaseUrl,
      this.tokenBaseUrl,
      this.apiKey
    );
    documentClient
      .get(fileId)
      .then((x) => {
        fileDownload(x, fileName ?? "");
      })
      .catch((e) => alert(e))
      .finally(() => (this.downloading = false));
  }

  async deleteFile(fileName: string, fileId: string) {
    this.loading = true;
    const documentClient = new DocumentClient(
      this.serviceBaseUrl,
      this.tokenBaseUrl,
      this.apiKey
    );
    await documentClient
      .delete(fileId)
      .then((x) => {
        this.removeFailed(fileName);
      })
      .finally(() => (this.loading = false));
  }

  async uploadSelectedFiles(filesToUpload: readonly File[]) {
    filesToUpload.forEach((file: any) => {
      var newFileInfo = new DocumentMetadataVm({
        id: "",
        fileName: file.name,
        fileSize: file.size,
        statusCode: this.getFileValid(file),
      });

      if (this.documentCategoryId.length > 0)
        newFileInfo.documentCategoryId = this.documentCategoryId;

      if (this.documentCategory.length > 0)
        newFileInfo.documentCategory = this.documentCategory;

      var index = this.fileInfos.findIndex((f) => f.fileName == file.name);
      if (index >= 0)
        this.fileInfos[index] = newFileInfo;
      else
        index = this.fileInfos.push(newFileInfo);

      //Do not upload invalid file
      if (newFileInfo.statusCode !== DocumentStatus.InProgress)
        return;

      const documentClient = new DocumentClient(
        this.serviceBaseUrl,
        this.tokenBaseUrl,
        this.apiKey
      );

      const queryString = this.constructUploadFileQueryString();

      this.loading = true;
      documentClient
        .put(`${queryString}`, file)
        .then((upload) => {
          if (upload.length < 1) return;
          this.fileInfos.splice(index - 1, 1, upload[0]);
        })
        .catch((e) => {
          console.error(e);
          this.fileInfos[index - 1].statusCode = DocumentStatus.ScanningError;
        })
        .finally(() => (this.loading = false));
    });
  }

  constructUploadFileQueryString = (): string => {
    let queryString = ``;
    
    if (this.entityName.length > 0) {
      queryString += `entityName=${this.entityName}`
    }

    if (this.entityId.length > 0) {
      queryString += queryString.length > 0 ? "&" : "";
      queryString += `entityId=${this.entityId}`
    }

    if (this.documentCategory.length > 0) {
      queryString += queryString.length > 0 ? "&" : "";
      queryString += `documentCategory=${this.documentCategory}`
    }

    if (this.documentCategoryId.length > 0) {
      queryString += queryString.length > 0 ? "&" : "";
      queryString += `documentCategoryId=${this.documentCategoryId}`
    }

    return queryString;
  };

  async handleFileChange(event: InputEvent) {
    if (event) {
      //@ts-ignore
      const files: ReadonlyArray<File> = [...(event.target.files ?? [])];
      this.uploadSelectedFiles(files);
    }
  }

  canUpload() {
    if (
      this.fileInfos.length >= this.maxDocuments ||
      (!this.multiple && this.fileInfos.length == 1) ||
      this.disabled
    ) {
      return false;
    }
    return true;
  }

  getStyleForStatus(status: DocumentStatus) {
    switch (status) {
      case DocumentStatus.InProgress:
        return 'alert blue doc-uploading';
      case DocumentStatus.Successfull:
      case DocumentStatus.InteralDocument:
        return 'alert success upload-success';
    }
    return 'alert red upload-failed';
  }

  removeFailed(fileName: string | undefined, event?: any) {
    if (event) event.preventDefault();
    this.fileInfos = this.fileInfos.filter((x) => x.fileName !== fileName);
  }

  toggleModel(fileName: string | undefined, fileId: string, event?: any) {
    if (event) event.preventDefault();
    this.showDeleteModal = !this.showDeleteModal;
    this.currentFileName = fileName ?? "";
    this.currentFileId = fileId;
  }

  getFileValid(file: File): DocumentStatus {
    if(file.size > this.maxFileSize) return DocumentStatus.TooLarge;
    const fileTypeValid = this.allowedFileTypes.some(type => file.name.endsWith(type));
    return fileTypeValid ? DocumentStatus.InProgress : DocumentStatus.InvalidFormat;
  }

  fileStatusCodeBad(status: DocumentStatus): boolean {
    return status == DocumentStatus.TooLarge 
      || status == DocumentStatus.MalwareDetected 
      || status == DocumentStatus.InvalidFormat
      || status == DocumentStatus.ScanningError;
  }

  getFileFailedText(fileStatus: DocumentStatus){
    if (fileStatus == DocumentStatus.InvalidFormat) 
      return 'The format of the file you have tried to upload is not allowed in the system. We are only allowing Word, Excel, PDF and text files.';
    if (fileStatus == DocumentStatus.TooLarge)
      return 'The file you have tried to upload is too large. The allowed maximum size is 30MB.';
    if (fileStatus == DocumentStatus.MalwareDetected)
      return 'The upload failed: this file was rejected by our system because it may be infected or unsafe. Please try saving again, or contact us if the problem persists.';
    return 'Upload failed due to a scanning error. Please try uploading again, or contact us if the problem persists.'
  }

  getFileSize(size: string | undefined) {
    return size !== null ? size : "0 B";
  }

  modalClosed(msg: any) {
    if (msg === "ok") {
      this.deleteFile(this.currentFileName, this.currentFileId);
    }
  }

  modalDeleteClicked() {
    this.deleteFile(this.currentFileName, this.currentFileId);
    this.showDeleteModal = false;
  }
}
