import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
class InputMixin extends Vue {
  @Prop() label!: string;

  innerValue: string | any[] | boolean = "";

  @Watch("innerValue")
  onPropertyChanged(value: string, oldValue: string) {
    this.$emit("input", value);
  }
}
export default InputMixin;
