


















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ProfileBanner extends Vue {
  @Prop() bannerText!: string;
  @Prop() bannerColour!: string;
  @Prop({ default: false }) showBanner!: boolean;

  close() {
    this.showBanner = false;
  }
}
