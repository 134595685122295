













































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DocumentClient,
  DocumentMetadataClient,
  DocumentMetadataVm,
} from "@/api/clients/documentApi";

import ZModal from "@/shared/components/ZModal.vue";
import { fileDownload } from "@/shared/HelperFunctions";

@Component({
  name: "supporting-material",
  components: {
    ZModal,
  },
})
export default class SupportingMaterial extends Vue {
  // APIs
  @Prop({ default: "" }) tokenBaseUrl!: string;
  @Prop({ default: "" }) serviceBaseUrl!: string;
  @Prop({ default: "" }) apiKey!: string;

  // Form Setup
  @Prop({ default: "" }) applicationId!: string;

  loading = true;
  downloading = false;

  files: { [category: string]: DocumentMetadataVm[] } = {};
  fileCategories: string[] = [];

  async created() {
    this.loading = true;
    await this.loadSupportingMaterial();
    this.loading = false;
  }

  async loadSupportingMaterial() {
    this.fileCategories.push("");
    this.files[""] = [];
    const docClient = new DocumentMetadataClient(
      this.serviceBaseUrl,
      this.tokenBaseUrl,
      this.apiKey
    );
    await docClient
      .getAll(
        "application",
        this.applicationId ?? "",
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then((files) => {
        files.forEach((file) => {
          const docCategory = file.documentCategory ?? "";
          if (!this.fileCategories.some((category) => category === docCategory))
            this.fileCategories.push(docCategory);
          if (!this.files[docCategory]) this.files[docCategory] = [];
          this.files[docCategory].push(file);
        });
      });
  }

  getFileSize(size: string | undefined) {
    if (size == null) return "0 B";
    return size;
  }

  async downloadFile(fileId: string, fileName: string | undefined, event?: any) {
    if (event) event.preventDefault();
    this.downloading = true;

    const documentClient = new DocumentClient(
      this.serviceBaseUrl,
      this.tokenBaseUrl,
      this.apiKey
    );
    documentClient
      .get(fileId)
      .then((x) => {
        fileDownload(x, fileName ?? "");
      })
      .catch((e) => alert(e))
      .finally(() => (this.downloading = false));
  }
}
