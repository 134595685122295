//Use for strings with format yyyy/mm/dd
export const getDateFromString = (date: string): Date => {
    if (!date.length) return new Date();
    const parts = date.split('-');
    const year = +parts[0];
    const month = +parts[1] - 1;
    const day = +parts[2];
    return new Date(Date.UTC(year, month, day));
  };

export const fileDownload = (x: any, fileName: string) => {
  const blob = new Blob([x]);
  const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
  
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', fileName);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.dispatchEvent(new MouseEvent('click'));
  //tempLink.click(); this just stopped working, and sent the user to the raw file
  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
  }, 200)
}