


















































enum DocumentStatus {
  Scanninginprogress = 1,
  Inactive = 2,
  TooLarge = 3,
  InvalidFormat = 4,
  Pending = 10,
  Successfullyuploaded = 395620001,
  Malwaredetected = 395620002,
}

import { Component, Emit, Mixins } from "vue-property-decorator";
import InputMixin from "./InputMixin";

@Component({
  components: {},
})
export default class FileInput extends Mixins(InputMixin) {
  private file: File | null = null;
  private fileStatus: DocumentStatus = 0;
  allowedFileTypes: string[] = [".pdf"];
  // 30mb
  maxFileSize: number = 31703520;

  canUpload() {
    return this.file == null;
  }

  async handleFileChange(event: InputEvent) {
    //@ts-ignore
    const files: ReadonlyArray<File> = [...(event.target.files ?? [])];
    if (files.length == 1) {
      this.file = files[0];
      this.fileStatus = this.getFileValid();
      this.fileUpdated();
    }
  }

  getFileValid(): DocumentStatus {
    if (this.file == null) return 0;
    if (this.file?.size > this.maxFileSize) return DocumentStatus.TooLarge;
    const fileTypeValid = this.allowedFileTypes.some((type) =>
      this.file?.name.endsWith(type)
    );
    return fileTypeValid
      ? DocumentStatus.Successfullyuploaded
      : DocumentStatus.InvalidFormat;
  }

  fileStatusCodeBad(): boolean {
    return (
      this.fileStatus == DocumentStatus.TooLarge ||
      this.fileStatus == DocumentStatus.Malwaredetected ||
      this.fileStatus == DocumentStatus.InvalidFormat
    );
  }

  getFileSize() {
    return this.file?.size ?? "0 B";
  }

  getFileFailedText() {
    if (this.fileStatus == DocumentStatus.InvalidFormat)
      return "The format of the file you have tried to upload is incorrect. This file must be a PDF.";
    if (this.fileStatus == DocumentStatus.TooLarge)
      return "The file you have tried to upload is too large. The allowed maximum size is 30MB.";
    return "Upload failed, please try again.";
  }

  getStyleForStatus() {
    switch (this.fileStatus) {
      case DocumentStatus.Pending:
        return "alert blue doc-uploading";
      case DocumentStatus.Malwaredetected:
      case DocumentStatus.InvalidFormat:
      case DocumentStatus.TooLarge:
        return "alert red upload-failed";
    }
    return "alert success upload-success";
  }

  removeFile() {
    this.file = null;
    this.fileUpdated();
  }

  fileUpdated() {
    if (this.fileStatus != DocumentStatus.Successfullyuploaded || this.file == null) {
      this.emitFile("");
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => this.emitFile(reader.result);
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  @Emit("file-changed")
  emitFile(fileString: string | ArrayBuffer | null) {
    return fileString ?? "";
  }
}
