



































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ZModal from "@/shared/components/ZModal.vue";

@Component({
  name: "sign-document-modal",
  components: {
    ZModal,
  },
})
export default class SignDocumentModal extends Vue {
  @Prop({ default: false }) showSignedModal!: boolean;
  @Prop() signing!: boolean;

  @Emit("sign-document")
  signDocument(event?: any) {
    if (event) event.preventDefault();
  }

  @Emit("close-sign-modal")
  closeSignModal() {}
}
