












import { Component, Mixins } from "vue-property-decorator";
import InputMixin from "./InputMixin";

@Component({})
export default class TextInput extends Mixins(InputMixin) {
}
