









































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import ZModal from "@/shared/components/ZModal.vue";
import DateInput from "@/shared/components/fields/DateInput.vue";
import TextInput from "@/shared/components/fields/TextInput.vue";
import FileInput from "@/shared/components/fields/FileInput.vue";
import { getDateFromString } from "@/shared/HelperFunctions";
import { IUploadSignedContractRequestVm } from "@/api/clients/signDocumentApi";

@Component({
  name: "upload-signed-document-modal",
  components: {
    ZModal,
    DateInput,
    TextInput,
    FileInput,
  },
})
export default class UploadSignedDocumentModal extends Vue {
  @Prop({ default: false }) showUploadModal!: boolean;
  @Prop() signing!: boolean;

  sigName = "";
  sigJobTitle = "";
  sigDate: Date | null = null;
  sigFile = "";

  fileUpdated(file: string | ArrayBuffer) {
    if (typeof file == "string") this.sigFile = file.split(",")[1];
    else this.sigFile = "";
  }

  sigNameUpdated(name: string) {
    this.sigName = name;
  }

  sigJobUpdated(job: string) {
    this.sigJobTitle = job;
  }

  sigDateUpdated(date: string) {
    this.sigDate = getDateFromString(date);
  }

  canUpload() {
    return (
      this.sigName != "" &&
      this.sigJobTitle != "" &&
      this.sigDate != undefined &&
      this.sigFile != ""
    );
  }

  @Emit("sign-uploaded-document")
  signUploadedDocument(event?: any) {
    if (event) event.preventDefault();
    var uploadRequest = {
      signedDocumentContent: this.sigFile,
      signatoryName: this.sigName,
      signatoryRole: this.sigJobTitle,
      signedOn: this.sigDate,
    } as IUploadSignedContractRequestVm;
    return uploadRequest;
  }

  @Emit("close-upload-modal")
  closeUploadModal() {}
}
