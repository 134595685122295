import { AxiosRequestConfig } from 'axios';
import { TokenService } from './tokenService';

export class AuthorizedApiBase {
    private tokenBaseUrl: string;
    private apiKey: string;

    constructor(tokenBaseUrl: string, apiKey: string) {
        this.tokenBaseUrl = tokenBaseUrl;
        this.apiKey = apiKey;
    }

    private getAuthorization = async () => {
        const result = await new TokenService(
            this.tokenBaseUrl
        ).getToken();

        return result.data.bearer ? result.data.bearer : result.data;
    };

    protected transformOptions = async (
        options: AxiosRequestConfig
      ): Promise<AxiosRequestConfig> => {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${await this.getAuthorization()}`
        };
        return Promise.resolve(options);
      };
}
