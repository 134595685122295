





























































import { Component, Mixins, Watch } from "vue-property-decorator";
import InputMixin from "./InputMixin";
import { DatePicker } from "element-ui";

@Component({
  components: {
    DatePicker,
  },
})
export default class DateInput extends Mixins(InputMixin) {
  days = this.createDays();
  months = this.getMonths();
  years = this.getRange(140, 1920).reverse();

  day = "";
  month = "";
  year = "";

  @Watch("day")
  onDayPropsPropertyChanged(value: string | number, oldValue: string) {
    if (value == null || value == "null") {
      this.resetDate();
      return;
    }
    this.recalculateInnerValue();
  }

  @Watch("month")
  onMonthPropsPropertyChanged(value: string | number, oldValue: string) {
    if (value == null || value == "null") {
      this.resetDate();
      return;
    }
    this.recalculateInnerValue();
  }

  @Watch("year")
  onYearPropsPropertyChanged(value: string | number, oldValue: string) {
    if (value == null || value == "null") {
      this.resetDate();
      return;
    }
    this.recalculateInnerValue();
  }

  @Watch("value")
  onPropsPropertyChanged(value: string, oldValue: string) {
    this.setupDates();
  }

  resetDate() {
    this.day = "";
    this.month = "";
    this.year = "";
    this.innerValue = "";
  }

  // "2021-03-17T11:00:00.000Z"
  recalculateInnerValue(): void {
    if (this.year && this.month && this.day) {
      this.innerValue = `${this.year}-${this.month}-${this.day}`;
    }
  }

  setupDates() {
    if (this.innerValue) {
      if (this.innerValue instanceof Date) {
        this.parseFromDate(this.innerValue);
      } else if (typeof this.innerValue === "string") {
        const date = new Date(this.innerValue);
        this.parseFromDate(date);
      }
    }
  }

  parseFromDate(date: Date) {
    this.year = date.getFullYear().toString();
    this.month = (date.getMonth() + 1).toString();
    this.day = date.getDate().toString();
    this.recalculateInnerValue();
  }

  createDays(): any {
    return Array.from({ length: 31 }, (x, i) => {
      return {
        value: i + 1,
        text: i + 1,
      };
    });
  }

  getRange(size: number, startAt: number = 0): any[] {
    return [...Array(size).keys()].map((i) => {
      return {
        value: i + startAt,
        text: i + startAt,
      };
    });
  }

  getMonths(): any {
    return [
      {
        value: 1,
        text: "January",
      },
      {
        value: 2,
        text: "February",
      },
      {
        value: 3,
        text: "March",
      },
      {
        value: 4,
        text: "April",
      },
      {
        value: 5,
        text: "May",
      },
      {
        value: 6,
        text: "June",
      },
      {
        value: 7,
        text: "July",
      },
      {
        value: 8,
        text: "August",
      },
      {
        value: 9,
        text: "September",
      },
      {
        value: 10,
        text: "October",
      },
      {
        value: 11,
        text: "November",
      },
      {
        value: 12,
        text: "December",
      },
    ];
  }
}
