import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { AuthorizedApiBase } from '../authorisedBase';

export class DocumentClient extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, tokenBaseUrl?: string, apiKey?: string, instance?: AxiosInstance) {
        super(tokenBaseUrl ?? "", apiKey ?? "");
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    put(querystring: string, formData: any, cancelToken?: CancelToken | undefined): Promise<DocumentMetadataVm[]> {
        let url_ = this.baseUrl + `/Forms/Document?${querystring}`;
        url_ = url_.replace(/[?&]$/, "");

        const data = new FormData();
        data.append('file', formData, formData.name);

        let options_ = <AxiosRequestConfig>{
            method: "PUT",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken,
            data: data,
            onUploadProgress: e => { },
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processPut(_response);
        });
    }

    protected processPut(response: AxiosResponse): Promise<DocumentMetadataVm[]> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            if (Array.isArray(resultData200)) {
                result200 = [] as any;
                for (let item of resultData200)
                    result200!.push(DocumentMetadataVm.fromJS(item));
            }
            else {
                result200 = <any>null;
            }
            return result200;
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ProblemDetails.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);
        } else if (status === 500) {
            const _responseText = response.data;
            return throwException("A server side error occurred.", status, _responseText, _headers);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<DocumentMetadataVm[]>(<any>null);
    }

    get(documentId: string, cancelToken?: CancelToken | undefined): Promise<any> {
        let url_ = this.baseUrl + "/Forms/Document/{documentId}";
        if (documentId === undefined || documentId === null)
            throw new Error("The parameter 'documentId' must be defined.");
        url_ = url_.replace("{documentId}", encodeURIComponent("" + documentId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
            },
            cancelToken,
            responseType: 'blob',
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processGet(_response);
        });
    }

    protected processGet(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>_responseText);
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ProblemDetails.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = ProblemDetails.fromJS(resultData404);
            return throwException("A server side error occurred.", status, _responseText, _headers, result404);
        } else if (status === 500) {
            const _responseText = response.data;
            return throwException("A server side error occurred.", status, _responseText, _headers);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }

    delete(documentId: string, cancelToken?: CancelToken | undefined): Promise<void> {
        let url_ = this.baseUrl + "/Forms/Document/{documentId}";
        if (documentId === undefined || documentId === null)
            throw new Error("The parameter 'documentId' must be defined.");
        url_ = url_.replace("{documentId}", encodeURIComponent("" + documentId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "DELETE",
            url: url_,
            headers: {
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processDelete(_response);
        });
    }

    protected processDelete(response: AxiosResponse): Promise<void> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            return Promise.resolve<void>(<any>null);
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ProblemDetails.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = ProblemDetails.fromJS(resultData404);
            return throwException("A server side error occurred.", status, _responseText, _headers, result404);
        } else if (status === 500) {
            const _responseText = response.data;
            return throwException("A server side error occurred.", status, _responseText, _headers);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<void>(<any>null);
    }
}

export class DocumentMetadataClient extends AuthorizedApiBase {
    private instance: AxiosInstance;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, tokenBaseUrl?: string, apiKey?: string, instance?: AxiosInstance) {
        super(tokenBaseUrl ?? "", apiKey ?? "");
        this.instance = instance ? instance : axios.create();
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    getAll(formType: string | null | undefined, relatedObjectId: string | null | undefined, formInstanceId: string | null | undefined, fieldsetInstanceId: string | null | undefined, fieldInstanceId: string | null | undefined, cancelToken?: CancelToken | undefined): Promise<DocumentMetadataVm[]> {
        let url_ = this.baseUrl + "/Forms/DocumentMetadata?";
        if (formType !== undefined && formType !== null)
            url_ += "formType=" + encodeURIComponent("" + formType) + "&";
        if (relatedObjectId !== undefined && relatedObjectId !== null)
            url_ += "relatedObjectId=" + encodeURIComponent("" + relatedObjectId) + "&";
        if (formInstanceId !== undefined && formInstanceId !== null)
            url_ += "formInstanceId=" + encodeURIComponent("" + formInstanceId) + "&";
        if (fieldsetInstanceId !== undefined && fieldsetInstanceId !== null)
            url_ += "fieldsetInstanceId=" + encodeURIComponent("" + fieldsetInstanceId) + "&";
        if (fieldInstanceId !== undefined && fieldInstanceId !== null)
            url_ += "fieldInstanceId=" + encodeURIComponent("" + fieldInstanceId) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processGetAll(_response);
        });
    }

    protected processGetAll(response: AxiosResponse): Promise<DocumentMetadataVm[]> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            if (Array.isArray(resultData200)) {
                result200 = [] as any;
                for (let item of resultData200)
                    result200!.push(DocumentMetadataVm.fromJS(item));
            }
            else {
                result200 = <any>null;
            }
            return result200;
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ProblemDetails.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);
        } else if (status === 500) {
            const _responseText = response.data;
            return throwException("A server side error occurred.", status, _responseText, _headers);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<DocumentMetadataVm[]>(<any>null);
    }

    get(documentMetadataId: string, cancelToken?: CancelToken | undefined): Promise<DocumentMetadataVm> {
        let url_ = this.baseUrl + "/Forms/DocumentMetadata/{documentMetadataId}";
        if (documentMetadataId === undefined || documentMetadataId === null)
            throw new Error("The parameter 'documentMetadataId' must be defined.");
        url_ = url_.replace("{documentMetadataId}", encodeURIComponent("" + documentMetadataId));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <AxiosRequestConfig>{
            method: "GET",
            url: url_,
            headers: {
                "Accept": "application/json"
            },
            cancelToken
        };

        return this.transformOptions(options_).then(transformedOptions_ => {
            return this.instance.request(transformedOptions_);
        }).catch((_error: any) => {
            if (isAxiosError(_error) && _error.response) {
                return _error.response;
            } else {
                throw _error;
            }
        }).then((_response: AxiosResponse) => {
            return this.processGet(_response);
        });
    }

    protected processGet(response: AxiosResponse): Promise<DocumentMetadataVm> {
        const status = response.status;
        let _headers: any = {};
        if (response.headers && typeof response.headers === "object") {
            for (let k in response.headers) {
                if (response.headers.hasOwnProperty(k)) {
                    _headers[k] = response.headers[k];
                }
            }
        }
        if (status === 200) {
            const _responseText = response.data;
            let result200: any = null;
            let resultData200 = _responseText;
            result200 = DocumentMetadataVm.fromJS(resultData200);
            return result200;
        } else if (status === 400) {
            const _responseText = response.data;
            let result400: any = null;
            let resultData400 = _responseText;
            result400 = ProblemDetails.fromJS(resultData400);
            return throwException("A server side error occurred.", status, _responseText, _headers, result400);
        } else if (status === 404) {
            const _responseText = response.data;
            let result404: any = null;
            let resultData404 = _responseText;
            result404 = ProblemDetails.fromJS(resultData404);
            return throwException("A server side error occurred.", status, _responseText, _headers, result404);
        } else if (status === 500) {
            const _responseText = response.data;
            return throwException("A server side error occurred.", status, _responseText, _headers);
        } else if (status !== 200 && status !== 204) {
            const _responseText = response.data;
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
        }
        return Promise.resolve<DocumentMetadataVm>(<any>null);
    }
}

export class DocumentMetadataVm implements IDocumentMetadataVm {
    id!: string;
    fileName?: string | undefined;
    fileSize?: string | undefined;
    documentCategoryId?: string | undefined;
    documentCategory?: string | undefined;
    status?: string | undefined;
    statusCode!: number;
    contractStatus?: string | undefined;
    contractStatusCode?: number | undefined;
    showInDocumentsTab? : boolean;
    formType?: string | undefined;
    relatedObjectId?: string | undefined;
    formInstanceId?: string | undefined;
    fieldsetInstanceId?: string | undefined;
    fieldInstanceId?: string | undefined;

    constructor(data?: IDocumentMetadataVm) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data["id"];
            this.fileName = _data["fileName"];
            this.fileSize = _data["fileSize"];
            this.documentCategoryId = _data["documentCategoryId"];
            this.documentCategory = _data["documentCategory"];
            this.status = _data["status"];
            this.statusCode = _data["statusCode"];
            this.contractStatus = _data["contractStatus"];
            this.contractStatusCode = _data["contractStatusCode"];
            this.showInDocumentsTab = _data["showInDocumentsTab"];
            this.formType = _data["formType"];
            this.relatedObjectId = _data["relatedObjectId"];
            this.formInstanceId = _data["formInstanceId"];
            this.fieldsetInstanceId = _data["fieldsetInstanceId"];
            this.fieldInstanceId = _data["fieldInstanceId"];
        }
    }

    static fromJS(data: any): DocumentMetadataVm {
        data = typeof data === 'object' ? data : {};
        let result = new DocumentMetadataVm();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["id"] = this.id;
        data["fileName"] = this.fileName;
        data["fileSize"] = this.fileSize;
        data["documentCategoryId"] = this.documentCategoryId;
        data["documentCategory"] = this.documentCategory;
        data["status"] = this.status;
        data["statusCode"] = this.statusCode;
        data["contractStatus"] = this.contractStatus;
        data["contractStatusCode"] = this.contractStatusCode;
        data["showInDocumentsTab"] = this.showInDocumentsTab;
        data["formType"] = this.formType;
        data["relatedObjectId"] = this.relatedObjectId;
        data["formInstanceId"] = this.formInstanceId;
        data["fieldsetInstanceId"] = this.fieldsetInstanceId;
        data["fieldInstanceId"] = this.fieldInstanceId;
        return data;
    }
}

export interface IDocumentMetadataVm {
    id: string;
    fileName?: string | undefined;
    fileSize?: string | undefined;
    documentCategoryId?: string | undefined;
    documentCategory?: string | undefined;
    status?: string | undefined;
    statusCode: number;
    contractStatus?: string | undefined;
    contractStatusCode?: number | undefined;
    showInDocumentsTab? : boolean;
    formType?: string | undefined;
    relatedObjectId?: string | undefined;
    formInstanceId?: string | undefined;
    fieldsetInstanceId?: string | undefined;
    fieldInstanceId?: string | undefined;
}

export class ProblemDetails implements IProblemDetails {
    type?: string | undefined;
    title?: string | undefined;
    status?: number | undefined;
    detail?: string | undefined;
    instance?: string | undefined;
    extensions?: { [key: string]: any; } | undefined;

    constructor(data?: IProblemDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.type = _data["type"];
            this.title = _data["title"];
            this.status = _data["status"];
            this.detail = _data["detail"];
            this.instance = _data["instance"];
            if (_data["extensions"]) {
                this.extensions = {} as any;
                for (let key in _data["extensions"]) {
                    if (_data["extensions"].hasOwnProperty(key))
                        (<any>this.extensions)![key] = _data["extensions"][key];
                }
            }
        }
    }

    static fromJS(data: any): ProblemDetails {
        data = typeof data === 'object' ? data : {};
        let result = new ProblemDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["type"] = this.type;
        data["title"] = this.title;
        data["status"] = this.status;
        data["detail"] = this.detail;
        data["instance"] = this.instance;
        if (this.extensions) {
            data["extensions"] = {};
            for (let key in this.extensions) {
                if (this.extensions.hasOwnProperty(key))
                    (<any>data["extensions"])[key] = this.extensions[key];
            }
        }
        return data;
    }
}

export interface IProblemDetails {
    type?: string | undefined;
    title?: string | undefined;
    status?: number | undefined;
    detail?: string | undefined;
    instance?: string | undefined;
    extensions?: { [key: string]: any; } | undefined;
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
}